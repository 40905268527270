import request from '@/request';

const part_url = "/share/dictionary/";
export default {
    /**
     * 获取所有的字典项
     */
    pageList(params) {
        let url = part_url + "getPageList";
        return request.get(url,params, {});
    },
    /**
     * 获取所有的字典详情
     */
    getById(params) {
        let url = part_url + "getById";
        return request.get(url,params, {});
    },
    /**
     * 修改
     */
    update(params) {
        let url = part_url + "update";
        return request.post(url,{}, params);
    },
    /**
     * 新增
     */
    save(params) {
        let url = part_url + "save";
        return request.post(url,{}, params);
    },
    

}