<template>
  <div class="tableList">
    
    <div class="tableItem">
      <a-table
        rowKey="id"
        :columns="tableInfo.columns"
        :data-source="tableInfo.datas"
        :pagination="tableInfo.pagination"
        :loading="loading"
        @change="onChange"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: tableInfo.tableHeight }"
      >
        <span slot="action" slot-scope="action">
          <p-button
            name="修改"
            @click="addOrUpdate(action)"
            size="small"
            type="primary"
          />
          <a-divider type="vertical" />
          <p-button
            name="数据"
            @click="showList(action)"
            size="small"
            type="dashed"
          />
        </span>
      </a-table>
    </div>
    <a-modal
      :title="editor.title"
      v-model="editor.visible"
      width="50%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <dictionaryInfo :info="editor.info" @callback="modelCallBack" />
    </a-modal>

    <!---子集-->
    <a-modal
       :title="itemListInfo.title"
      v-model="itemListInfo.visible"
      :footer="false"
      destroyOnClose
      width="80%"
      :maskClosable="false"
    >
      <itemList @callbackMethod="modelCallBack" :info="itemListInfo.info" />
    </a-modal>
  </div>
</template>

<script>
const columns = [
 
  {
    dataIndex: "name",
    key: "name",
    align: "center",
    title: "名称",
    width: 200
  },
  {
    dataIndex: "description",
    key: "description",
    align: "center",
    title: "描述"
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "action" }
  },
  
];

import dictionaryInfo from "./DictionaryInfo";
import itemList from "./DictionaryItemList.vue"; 
import dictionaryApi from "@/api/system/dictionary";
export default {
  name: "DictionaryList",
  data() {
    return {
      loading: false,
      search: {
        pageNow: 1,
        pageSize: 10,
        name: "",
      },
      tableInfo: {
        datas: [],
        columns,
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          defaultCurrent: 1,
          defaultPageSize: 10,
          showTotal: (total) => `总共${total}条`,
        },
        // tableHeight: window.innerHeight - 300,
      },
      selectRows: [],
      editor: {
        title: "编辑",
        visible: false,
        info: {},
      },
      itemListInfo: {
        title: "",
        info: {},
        visible: false
      },
    };
  },
  components: {
    dictionaryInfo,
    itemList
  },
  mounted() {
    this.getPageList();
  },
  methods: {
    async getPageList() {
      let _this = this;
      let param = {
        pageNow: _this.search.pageNow,
        pageSize: _this.search.pageSize,
        name: _this.search.name,
      };
      dictionaryApi.pageList(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.tableInfo.datas = res.data.records;
          _this.tableInfo.pagination.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    onChange(page) {
      let _this = this;
      _this.search.pageNow = page.current;
      _this.search.pageSize = page.pageSize;
      _this.getPageList();
    },
    searchList() {
      let _this = this;
      _this.getPageList();
    },
    
    addOrUpdate(info) {
      let _this = this;
      _this.editor.visible = true;
      if (info) {
        _this.editor.title = "编辑";
        _this.editor.info = info;
      } else {
        _this.editor.title = "新增";
        info = {
          id: null,
        };
        _this.editor.info = info;
      }
    },
   showList(info) {
      //打开弹出框
      let _this = this;
      _this.itemListInfo.visible = true;
      _this.itemListInfo.title ='字典子集';
      _this.itemListInfo.info = info;
    },
    modelCallBack() {
      this.editor.visible = false;
      this.getPageList();
    },
    
  },
};
</script>

<style lang="less">
</style>