<template>
  <a-spin :tip="loadingTip" :spinning="loading">
    <a-form-model
      ref="dictionaryItemInfo"
      :model="dictionaryItemInfo"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="名称" prop="itemName">
        <a-input v-model="dictionaryItemInfo.itemName" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="父级" v-if="dinfo.type == 2">
        {{ areaInfo.text }}
        <a-cascader
          :options="areaData"
          :fieldNames="{ label: 'itemName', value: 'id', children: 'children' }"
          @change="onChange"
          changeOnSelect
        >
          <a-button type="primary" icon="search" style="margin-left: 10px"
            >选择</a-button
          >
        </a-cascader>
      </a-form-model-item>
      <a-form-model-item label="字典项值" prop="itemValue">
        <a-input
          v-model="dictionaryItemInfo.itemValue"
          placeholder="请输入字典项值"
        />
      </a-form-model-item>
      <a-form-model-item label="排序" prop="orderno">
        <a-input
          v-model="dictionaryItemInfo.orderno"
          placeholder="请输入排序"
        />
      </a-form-model-item>
      <a-form-model-item label="图片">
        <a-upload
          :action="upLoadUrl"
          listType="picture-card"
          :fileList="fileList"
          :beforeUpload="beforeUpload"
          @preview="handlePreview"
          @change="handleChange"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
        <a-modal
          :visible="previewVisible"
          :footer="null"
          @cancel="handleCancel"
        >
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </a-form-model-item>
      <a-form-model-item label="前台显示" v-if="dinfo.type == 4">
        <a-switch v-model="dictionaryItemInfo.isShow" />
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <p-button type="primary" @click="onSubmit" name="提交" />
        <p-button style="margin-left: 10px" name="取消" @click="cancelSubmit" />
      </a-form-model-item>
    </a-form-model>
  </a-spin>
</template>

<script>
import dictionaryItemApi from "@/api/system/dictionaryItem";
import request from "@/request"
export default {
  name: "DictionaryInfo",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    dinfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadingTip: "加载中...",
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      dictionaryItemInfo: {
        orderno: 0,
        parentId: 0,
        isShow: true,
      },
      fileList: [],
      previewVisible: false,
      previewImage: "",
      upLoadUrl:request.getUploadUrl('system'),
      areaData: [
        {
          id: "0",
          itemName: "根目录",
          children: [],
        },
      ],
      areaInfo: {
        text: "根目录",
        id: 0,
      },
      rules: {
        itemName: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        itemValue: [
          {
            required: true,
            message: "请输入字典项值",
            trigger: "blur",
          },
        ],
        orderno: [
          {
            required: true,
            message: "请输入排序",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    if (this.info.id) {
      this.getById(this.info.id);
      this.getTree();
    }
  },
  methods: {
    onSubmit() {
      let _this = this;
      _this.$refs.dictionaryItemInfo.validate((valid) => {
        if (valid) {
         
          let _info = {
            id: _this.info.id,
            dictionaryId: _this.dinfo.id,
            itemName: _this.dictionaryItemInfo.itemName,
            orderno: _this.dictionaryItemInfo.orderno,
            itemValue: _this.dictionaryItemInfo.itemValue,
            parentId: _this.dictionaryItemInfo.parentId,
            isShow: _this.$util.changeBooleanInt(
              _this.dictionaryItemInfo.isShow
            ),
            picture: _this.picArr ? _this.picArr.join(",") : "",
          };
         
          if (_info.id) {
            dictionaryItemApi.update(_info).then((res) => {
              if (res.errorCode === _this.$commons.RespCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callback");
              } else {
                _this.$message.error(res.errorMsg);
              }
            });
          } else {
            dictionaryItemApi.save(_info).then((res) => {
              if (res.errorCode === _this.$commons.RespCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callback");
              } else {
                _this.$message.error(res.errorMsg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    getById(id) {
      let _this = this;
      let param = {
        id: id,
      };
      dictionaryItemApi.getById(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.dictionaryItemInfo = res.data;
          if (_this.dinfo.type == 2) {
            _this.areaInfo.text =
              res.data.parentId == 0 ? "根目录" : res.data.parentName;
            _this.areaInfo.id = res.data.parentId;
          }
          if (_this.dinfo.type == 4) {
            _this.info.isShow = res.data.isShow && res.data.isShow=="1";
          }
          if (res.data.picture.length > 0) {
            var pics = res.data.picture.split(",");
            for (var i = 0; i < pics.length; i++) {
              _this.fileList.push({
                uid: i,
                name: "图片" + (i + 1) + ".png",
                status: "done",
                url: pics[i],
              });
            }
          }
        } else {
          _this.$message.error(res.errorMsg);
        }
      });
    },
    async getTree() {
      let _this = this;
      let param={
        dictionaryId:this.info.dictionaryId
      }
      dictionaryItemApi.tree(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.areaData[0].children = res.data;
        } else {
          _this.$message.error(res.errorMsg);
        }
      });
    },
    beforeUpload(file) {
      let _this = this;
      var isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        _this.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
    onChange(value, selectedOptions) {
      let _this = this;
      if (_this.infos.id) {
        if (_this.infos.id == selectedOptions[selectedOptions.length - 1].id) {
          this.$message.error("不能选择自己作为父级");
          return false;
        }
      }
      _this.areaInfo.text =
        selectedOptions[selectedOptions.length - 1].itemName;
      _this.areaInfo.id = selectedOptions[selectedOptions.length - 1].id;
      _this.info.parentId = _this.areaInfo.id;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
    },
    handleChange(info) {
      let _this = this;
      if (info.fileList.length > 0) {
        var file = info.file;
        if (file.type != "image/jpeg" && file.type != "image/png") {
          return;
        }
      }

      _this.fileList = info.fileList;
    },
    cancelSubmit() {
      this.$emit("callback");
    },
  },
};
</script>