<template>
  <a-spin :tip="loadingTip" :spinning="loading">
    <a-form-model
      ref="dictionaryForm"
      :model="dictionaryInfo"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="dictionaryInfo.name" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input
          v-model="dictionaryInfo.description"
          placeholder="请输入名称"
        />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-select v-model="dictionaryInfo.type" placeholder="请选择" allowClear>
          <a-select-option :value="1">键值对</a-select-option>
          <a-select-option :value="2">树形</a-select-option>
          <a-select-option :value="4">标签</a-select-option>
          <!--<a-select-option :value="3">图形</a-select-option> -->
        </a-select>
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <p-button type="primary" @click="onSubmit" name="提交" />
        <p-button style="margin-left: 10px" name="取消" @click="cancelSubmit" />
      </a-form-model-item>
    </a-form-model>
  </a-spin>
</template>

<script>
import dictionaryApi from "@/api/system/dictionary";
export default {
  name: "DictionaryInfo",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadingTip: "加载中...",
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      dictionaryInfo: {
        
      },
      
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    if (this.info.id) {
      this.requiredPass = false;
      this.getById(this.info.id);
    }
  },
  methods: {
    onSubmit() {
      let _this = this;
      _this.$refs.dictionaryForm.validate((valid) => {
        if (valid) {
          
          if (_this.dictionaryInfo.id) {
            dictionaryApi.update(_this.dictionaryInfo).then((res) => {
              if (res.errorCode === _this.$commons.RespCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callback");
              } else {
                _this.$message.error(res.errorMsg);
              }
            });
          } else {
            dictionaryApi.save(_this.dictionaryInfo).then((res) => {
              if (res.errorCode === _this.$commons.RespCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callback");
              } else {
                _this.$message.error(res.errorMsg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    getById(id) {
      let _this = this;
      let param = {
        id: id,
      };
      dictionaryApi.getById(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.dictionaryInfo = res.data;
         
        } else {
          _this.$message.error(res.errorMsg);
        }
      });
    },
    cancelSubmit() {
      this.$emit("callback");
    },
  },
};
</script>